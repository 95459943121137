<template>
  <div>
    <SubmitButton class="edit-button" @onButtonClick="submitEditUser">
      <font-awesome-icon icon="check" /> Save
    </SubmitButton>
    <h3>Manage Profile</h3>
    <div class="profile-wrapper">
      <template v-if="defaultInfo">
        <div class="form">
          <div class="form-group">
            <div class="label">Display Name</div>
            <div class="info">
              <input
                disabled
                class="info-input"
                v-model="defaultInfo.display_name"
                type="text"
                placeholder="Enter Display Name"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="label">
              Name <br />
              (As per NRIC/Passport/Other ID)
            </div>
            <div class="info">
              <input
                class="info-input"
                v-model="defaultInfo.name"
                type="text"
                placeholder="Enter Name"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="label">Mobile Number</div>
            <div class="info">
              <input
                class="info-input"
                v-model="defaultInfo.phone"
                type="text"
                placeholder="Enter No."
              />
            </div>
          </div>
          <div class="form-group">
            <div class="label">Email</div>
            <div class="info">
              <input
                disabled
                class="info-input"
                v-model="email"
                type="text"
                placeholder="Enter Email"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="label">Nationality</div>
            <div class="info">
              <v-select
                class="info-select v-select-box"
                v-model="defaultInfo.country"
                :options="countryList"
                label="name"
                :clearable="false"
              ></v-select>
            </div>
          </div>
          <div class="form-group">
            <div class="label">State</div>
            <div class="info">
              <v-select
                class="info-select v-select-box"
                v-model="defaultInfo.state"
                :options="stateList"
                label="name"
                :clearable="false"
              ></v-select>
            </div>
          </div>
          <div class="form-group">
            <div class="label">Gender</div>
            <div class="info">
              <v-select
                class="info-select v-select-box"
                :value="getGender"
                :options="genderList"
                @input="setGender"
                :clearable="false"
              ></v-select>
            </div>
          </div>
        </div>
      </template>
      <div class="avatar">
        <div class="user-avatar">
          <img v-if="!attachment" :src="getImage" alt="" />
          <img v-else :src="preview" alt="avatar" />
        </div>
        <input
          :style="{ display: 'none' }"
          ref="fileUpload"
          type="file"
          accept="image/*"
          @change="onFileChanged"
        />
        <div class="select-image" @click="$refs.fileUpload.click()">
          Select Image
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";

import Avatar from "@/assets/images/default_avatar_2x.png";

import { getCountry, getState } from "@/services/api/countries.js";
import { attachments } from "@/services/api/others.js";
import { userProfile, updateProfile } from "@/services/api/users.js";

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      defaultInfo: "",
      email: "",
      countryList: [],
      stateList: [],
      genderList: ["Female", "Male"],
      defaultAvatar: Avatar,
      attachment: null,
      preview: null,
    };
  },
  computed: {
    getImage() {
      if (this.defaultInfo) {
        return this.defaultInfo.avatar_arr[0];
      }
      return "";
    },
    getGender() {
      return this.defaultInfo.gender === "F" ? "Female" : "Male";
    },
  },
  methods: {
    setGender(item) {
      this.defaultInfo.gender = item === "Female" ? "F" : "M";
    },
    onFileChanged(e) {
      const filename = e.target.files[0].name;
      const fileType = e.target.files[0].type;

      //Check file validity
      if (filename.lastIndexOf(".") <= 0 || !fileType.includes('image')) {
        return this.$swal({
          title: 'Invalid Attachment',
          text: 'File Type Not Allowed',
          icon: 'error',
        });
      }else if(filename.split('.').length > 2){
        return this.$swal({
          title: 'Invalid Attachment',
          text: 'File must not include more than 1 period',
          icon: 'error',
        });
      }

      this.attachment = e.target.files[0];
      this.preview = URL.createObjectURL(e.target.files[0]);
    },
    async submitEditUser() {

      const dataToSend = (({name, phone, gender,country,state, role}) => (
        {
          name, 
          phone, 
          gender,
          country_id: country.id, 
          state_id: state.id, 
          role_name : role.name
        }
      ))(this.defaultInfo);

      if(this.attachment){
        const formData = new FormData();
        formData.append("attachment", this.attachment);
        const response = await attachments(formData);
        console.log(response);
        dataToSend.avatar = response.data.response.filename
      }

      const req = await updateProfile(dataToSend);
      this.$store.commit('updateProfileDetails',req.data.response.user);
      this.$swal({
        icon: 'success',
        title: 'Profile Updated',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      }).then(() => {
        this.$router.push({name: 'Profile'})
      })
    },
  },
  async mounted() {
    let req = await userProfile();
    this.defaultInfo = req.data.response.user;
    this.email = this.defaultInfo.email;
    try {
      const response = await getCountry();
      const response2 = await getState(this.defaultInfo.country_id);
      if (response.status === 200)
        this.countryList = response.data.response.countries;
      if (response2.status === 200)
        this.stateList = response2.data.response.states;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style lang="scss" scoped>
.edit-button {
  display: inline;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.75rem;
  padding: 0.75rem;
  // float: right;
}

.info-input{
  font-size: 14px;
}

.profile-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-areas: "form avatar";
  gap: 0.5rem;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "avatar"
      "form";
    text-align: start;
    font-size: $twelve;
  }
  .form {
    grid-area: form;
    position: relative;
    &-group {
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      .label {
        width: 40%;
        margin: auto;
        &::after {
          content: "";
          height: 100%;
          position: absolute;
          top: 0%;
          left: 40%;
          border-right: 1px solid $gray;
          
          @include respond(tab-port) {
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-top: $gray solid 1px;
            border-right: 0;
          }
        }
      }
      .info {
        width: 60%;
        margin: auto;
        margin-left: $ten;
        // display: inline-block;

        &-input {
          width: 98%;
          vertical-align: middle;
          padding: $ten $twelve;
          border-radius: 5px;
          @include respond(tab-port) {
            width: 100%;
          }
        }
        &-select {
          width: 98%;
          @include respond(tab-port) {
            width: 100%;
          }
        }
      }
      .view-btn {
        // max-width: width 10px;
        padding: 0.75rem 1rem;

        font-size: 12px;
        word-wrap: normal;
        // color: #000;
      }
    }
  }

  .avatar {
    grid-area: avatar;
    position: relative;
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10%;
      height: 100%;
      border-right: solid $gray 1px;
      @include respond(tab-port) {
        display: none;
      }
    }
    .user-avatar {
      font-size: 10vw;
      width: 100%;
      margin: 0 auto;
      max-width: 40 * $ten;
      margin-bottom: 1rem;
      img {
        width: 100%;
      }
      @include respond(tab-port) {
        font-size: 10rem;
        max-width: 100px;
      }
    }
    .select-image {
      width: 100%;
      font-size: $twelve;
      padding: 0.75rem;
      max-width: 18.75rem;
      margin: 0 auto;
      word-wrap: none;
      text-align: center;
      color: $white;
      background: $grayDark;
      border-radius: 10rem;
      cursor: pointer;
      // float: right;
    }
  }
}
</style>
